import React from 'react';
import {withRouter} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Email as EmailIcon, Smartphone as PhoneIcon,} from "@mui/icons-material";
import {CompanyLogo, FollowUs} from "@atttomyx/react-components";
import {communication, router} from "@atttomyx/react-utils";
import {windowUtils} from "../../utils";
import {milesoft} from "@atttomyx/shared-constants";
import {
    DOTS_NAME,
    PAGE_ACCEPTABLE_USE_POLICY,
    PAGE_DOTS,
    PAGE_PRIVACY_POLICY,
    PAGE_REVEAL,
    PAGE_SCHEDULE,
    PAGE_SUDOKU,
    PAGE_USER_TERMS_OF_SERVICE,
    PAGE_WORDS,
    REVEAL_NAME,
    SCHEDULE_NAME,
    SUDOKU_NAME,
    WORDS_NAME
} from "../../constants";
import "./home.css";
import {dots, reveal, schedule, sudoku, words} from "../../images/index";

const useStyles = makeStyles((theme) => ({
    primaryText: {
        color: theme.palette.primary.main,
    },
    secondaryText: {
        color: theme.palette.secondary.main,
    },
}));

const Home = (props) => {
    const { history } = props;
    const classes = useStyles();

    const renderApp = (name, icon, page) => {
        return <ListItemButton onClick={() => router.redirectTo(history, page)}>
            <ListItemIcon><img src={icon} className="icon" alt={name}/></ListItemIcon>
            <ListItemText className={classes.primaryText}>{name}</ListItemText>
        </ListItemButton>
    };

    const renderLink = (name, page) => {
        return <ListItemButton onClick={() => router.redirectTo(history, page)}>
            <ListItemText className={classes.secondaryText}>{name}</ListItemText>
        </ListItemButton>
    };

    const renderEmail = (email) => {
        return <ListItemButton onClick={() => communication.popEmail(email)}>
            <ListItemIcon><EmailIcon/></ListItemIcon>
            <ListItemText className={classes.secondaryText}>{email}</ListItemText>
        </ListItemButton>
    };

    const renderPhone = (phone) => {
        return <ListItem>
            <ListItemIcon><PhoneIcon/></ListItemIcon>
            <ListItemText className="lighter">{phone}</ListItemText>
        </ListItem>
    };

    return <div className="home">
        <CompanyLogo mode="logo" size="medium"/>
        <Typography variant="body1">
            Custom app development
        </Typography>
        <div className="cards">
            <div className="card">
                <Typography variant="h5">
                    Our Apps
                </Typography>
                <List>
                    {renderApp(SCHEDULE_NAME, schedule, PAGE_SCHEDULE)}
                    {renderApp(SUDOKU_NAME, sudoku, PAGE_SUDOKU)}
                    {renderApp(WORDS_NAME, words, PAGE_WORDS)}
                    {renderApp(DOTS_NAME, dots, PAGE_DOTS)}
                    {renderApp(REVEAL_NAME, reveal, PAGE_REVEAL)}
                </List>
            </div>
            <div className="card">
                <Typography variant="h5">
                    Billing
                </Typography>
                <Button color="secondary" size="large" className="billing"
                        onClick={windowUtils.openBilling}>
                    Make a payment
                </Button>
            </div>
            <div className="card">
                <Typography variant="h5">
                    Contact Us
                </Typography>
                <List>
                    {renderEmail(milesoft.EMAIL_SUPPORT)}
                    {renderPhone(milesoft.PHONE_SUPPORT)}
                </List>
            </div>
            <div className="card">
                <Typography variant="h5">
                    Follow Us
                </Typography>
                <FollowUs/>
            </div>
            <div className="card">
                <Typography variant="h5">
                    Legal
                </Typography>
                <List>
                    {renderLink("User Terms of Service", PAGE_USER_TERMS_OF_SERVICE)}
                    {renderLink("Acceptable Use Policy", PAGE_ACCEPTABLE_USE_POLICY)}
                    {renderLink("Privacy Policy", PAGE_PRIVACY_POLICY)}
                </List>
            </div>
        </div>
    </div>
}

export default withRouter(Home);
