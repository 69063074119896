import React from 'react';
import {Divider} from "@mui/material";
import {AppStoreLinks} from "@atttomyx/react-components";
import {MilesoftApp, PointOfInterest, Screenshots, WatchDemoButton} from "../../components";
import {milesoft} from "@atttomyx/shared-constants";
import {
    WORDS_APP_ID_APPLE,
    WORDS_APP_ID_GOOGLE,
    WORDS_NAME,
    WORDS_TAG_LINE,
    WORDS_VIDEO_ID_LANDSCAPE,
    WORDS_VIDEO_ID_PORTRAIT
} from "../../constants";
import "./words.css";
import {
    words as icon,
    words1,
    words10,
    words2,
    words3,
    words4,
    words5,
    words6,
    words7,
    words8,
    words9
} from "../../images/index";

const Words = (props) => {
    const { inline } = props;

    return <div className="words">
        {!inline ? <>
            <MilesoftApp
                icon={icon}
                name={WORDS_NAME}
                tagLine={WORDS_TAG_LINE}
            />
            <AppStoreLinks
                appleId={WORDS_APP_ID_APPLE}
                googleId={WORDS_APP_ID_GOOGLE}
            />
            <WatchDemoButton
                title={`${milesoft.COMPANY_NAME} ${WORDS_NAME}`}
                videoIdLandscape={WORDS_VIDEO_ID_LANDSCAPE}
                videoIdPortrait={WORDS_VIDEO_ID_PORTRAIT}
            />
            <Divider/>
        </> : null}
        <div className="cards points-of-interest">
            <PointOfInterest
                title="How to play"
                description={<>
                    Make a guess. From that, you will learn:
                    <ul>
                        <li className="red">letter is not in the word</li>
                        <li className="yellow">letter is in the word, wrong spot</li>
                        <li className="green">letter is in the word, right spot</li>
                    </ul>
                    Keep guessing until you get the word.
                    </>}
            />
            <PointOfInterest
                title="Hint"
                description="Your guesses do not have to be words. You can just use letters from words that you think might be it based on what you already know."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[words1, words2, words3]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Daily challenges"
                description="Every day presents three new challenges: Easy, Medium, and Hard. All players may participate and see each others' score."
            />
            <PointOfInterest
                title="Friend challenges"
                description="You may also play private challenges with groups of friends, or one-on-one."
            />
            <PointOfInterest
                title="Hint"
                description="Get a particularly good score while playing alone? Send that word as a challenge to your friends!"
            />
        </div>
        <Divider/>
        <Screenshots
            images={[words4, words5, words6, words7]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="All-time leaderboards"
                description="The all-time leaderboards are based on prestige. Raise your prestige by earning stars by guessing words! Maintaining a low average (guesses per word) also helps."
            />
            <PointOfInterest
                title="Weekly leaderboards"
                description="The weekly leaderboards reset once a week. You can zip to the top instantly by doing very well in a single game! Playing lots of games also helps."
            />
            <PointOfInterest
                title="Hint"
                description="Your score will be highlighted in green."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[words8, words9]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Offline"
                description="No internet? No worries. Although you won't be able to compete in challenges until you are back online, you will still be able to guess words!"
            />
            <PointOfInterest
                title="Hint"
                description="The game's dictionary contains more than 2,500 words. It is unlikely that you will see a word repeated for quite some time."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[words10]}
        />
    </div>
}

export default Words;
