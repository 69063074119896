import React, {useMemo, useState} from 'react';
import {isMobile} from "react-device-detect";
import {Button} from "@mui/material";
import {YouTubeDialog} from "@atttomyx/react-components";

const WatchDemoButton = (props) => {
    const {title, videoIdLandscape, videoIdPortrait} = props;
    const [showDemo, setShowDemo] = useState(false);

    const mode = useMemo(() => {
        return (isMobile && videoIdPortrait) || !videoIdLandscape? "portrait" : "landscape";
    }, [videoIdLandscape, videoIdPortrait, isMobile]);

    const videoId = useMemo(() => {
        return "portrait" === mode ? videoIdPortrait : videoIdLandscape;
    }, [videoIdLandscape, videoIdPortrait, mode]);

    return <div className="watch-demo-button">
        <Button
            color="primary"
            size="large"
            onClick={() => setShowDemo(true)}
        >
            Watch demo
        </Button>
        {showDemo ?
            <YouTubeDialog
                title={title}
                videoId={videoId}
                mode={mode}
                onClose={() => setShowDemo(false)}
            /> : null}
    </div>
}

export default WatchDemoButton;
