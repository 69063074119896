import React from 'react';
import {Divider} from "@mui/material";
import {AppStoreLinks} from "@atttomyx/react-components";
import {MilesoftApp, PointOfInterest, Screenshots, WatchDemoButton} from "../../components";
import {milesoft} from "@atttomyx/shared-constants";
import {
    DOTS_APP_ID_APPLE,
    DOTS_APP_ID_GOOGLE,
    DOTS_NAME,
    DOTS_TAG_LINE,
    DOTS_VIDEO_ID_LANDSCAPE,
    DOTS_VIDEO_ID_PORTRAIT
} from "../../constants";
import "./dots.css";
import {
    dots as icon,
    dots1,
    dots10,
    dots11,
    dots12,
    dots13,
    dots14,
    dots2,
    dots3,
    dots4,
    dots5,
    dots6,
    dots7,
    dots8,
    dots9
} from "../../images/index";

const Dots = (props) => {
    const { inline } = props;

    return <div className="dots">
        {!inline ? <>
            <MilesoftApp
                icon={icon}
                name={DOTS_NAME}
                tagLine={DOTS_TAG_LINE}
            />
            <AppStoreLinks
                appleId={DOTS_APP_ID_APPLE}
                googleId={DOTS_APP_ID_GOOGLE}
            />
            <WatchDemoButton
                title={`${milesoft.COMPANY_NAME} ${DOTS_NAME}`}
                videoIdLandscape={DOTS_VIDEO_ID_LANDSCAPE}
                videoIdPortrait={DOTS_VIDEO_ID_PORTRAIT}
            />
            <Divider className="top"/>
        </> : null}
        <div className="cards points-of-interest">
            <PointOfInterest
                title="How to play"
                description="Tapping the screen will begin an explosive chain reaction of exploding dots. Try to clear all of the dots! The less taps you use, the more stars you will earn."
            />
            <PointOfInterest
                title="Campaign"
                description="Levels start off easy but get progressively more difficult. It serves as a tutorial to help you learn the game. Beat it to unlock the other modes."
            />
            <PointOfInterest
                title="Timed"
                description="You play five rounds per game, each round a bit tougher than the previous. Try to clear the dots as fast as you can!"
            />
            <PointOfInterest
                title="Limited"
                description="You play multiple rounds, each progressively harder. You are limited to a total of 12 taps, so the fewer you use per round the farther you advance."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots1, dots2, dots3]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Scoring"
                description={<>
                    There are five kinds of dots:&nbsp;
                    <span className="green">green</span>, <span className="blue">blue</span>, <span
                    className="purple">purple</span>, <span className="orange">orange</span>, and <span
                    className="red">red</span>.
                    Each is progressively smaller, faster, and worth more points. In a chain reaction, each explosion
                    increases a multiplier applied to the dots as they explode. For the best score, orchestrate a chain
                    reaction that starts with low-point dots and ends with high-point dots.
                </>}
            />
            <PointOfInterest
                title="Leaderboards"
                description="Compete for the top spots on multiple leaderboards! The Campaign leaderboards are permanent, but the Timed and Limited leaderboards reset every week."
            />
            <PointOfInterest
                title="Stats"
                description="Your personal stats are permanent. Keep track of how you are doing, and note areas of improvement to help climb the leaderboards."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots4, dots5]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Premium power-ups"
                description="Premium power-ups are purchased with gems and passively apply a bonus (such as doubling the amount of gold you earn) for 30 minutes."
            />
            <PointOfInterest
                title="Standard power-ups"
                description={`Standard power-ups are purchased with gold and are used while playing a game. Tap once on a power-up's icon and then once in the play area to use it (tapping again will cause a chain reaction, as normal). Double-tap on an icon to "lock" it for multiple uses.`}
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots6, dots7]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Block"
                description="Block allows you to place an obstacle in the play area that dots will bounce off of without exploding. A preview of the next shape is shown on the corner of the Block icon."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots8, dots9]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Shockwave"
                description="Dots accelerate away from Shockwave when hit by it, without exploding. This allows you to propel a dot into an explosion it would have otherwise missed, granting you a higher score and more stars."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots10]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Freeze"
                description="Holds a dot in place for 10 seconds, and reduces its hit points to 1. This allows you to negate villain dots' abilities and to defeat a boss dot in a convenient location so that its stone won't cause much trouble."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots14]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Duplicate"
                description="Duplicate allows you to add extra dots to the play area, making it easier to create a chain reaction. Extra dots are not worth points and won't count against you if missed. Best used on levels with few dots."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots11, dots12]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Magnetism"
                description={<>
                    Magnetism is <strong>the</strong> strongest power-up. When used, all dots are immediately drawn to where you tapped.
                </>}
            />
        </div>
        <Divider/>
        <Screenshots
            images={[dots13]}
        />
    </div>
}

export default Dots;
